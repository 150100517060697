import * as braze from "@braze/web-sdk";
import { format, setMonth, setYear } from "date-fns";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import useElement from "src/lib/layers/useElement";

import TeamContext from "src/components/context/TeamContext";
import { useTeamReports } from "src/components/context/TeamReportsContext";
import { UserDataContext } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import Loader from "src/components/elements/Loader";
import MobMenu from "src/components/elements/MobMenu";

import DateRangePicker, {
  DATE_RANGES,
  DateRangeData,
} from "src/components/blocks/DateRangePicker";
import FilterDrivers from "src/components/blocks/reports/teams/FilterDrivers";
import FilterMonths from "src/components/blocks/reports/teams/FilterMonths";
import ReportSidebar from "src/components/blocks/reports/teams/ReportSidebar";
import ReportsList from "src/components/blocks/reports/teams/ReportsList";

import { ELEMENT_ID } from "src/components/modals/ConsolidatedReports";

import PageLayout from "src/components/PageLayout";

import useQueryParams from "src/hooks/useQueryParams";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";

import { MEMBER_STATUS } from "src/models/team-member";

import { getTeamMembers } from "src/services/teams";
import {
  teamsReportsDriversFilters,
  teamsReportsSubmissionFilters,
  trackTeamsConsolidatedReportStarted,
  trackTeamsReportsFiltered,
} from "src/services/tracking";

export default ReportsPage;

const PAGE_STATES = {
  IDLE: "IDLE",
  DRIVERS_LOADING: "DRIVERS_LOADING",
  DRIVERS_LOADED: "DRIVERS_LOADED",
  DRIVERS_ERROR: "DRIVERS_ERROR",
};

const DEFAULT_RANGE_TYPE = DATE_RANGES.LAST_30_DAYS;

function ReportsPage() {
  const history = useHistory();
  const { team } = useContext(TeamContext);
  const { userData } = useContext(UserDataContext);

  const queryParams = useQueryParams();
  const driversParam = queryParams.get("drivers");
  const rangeParam = queryParams.get("range");
  const monthParam = queryParams.get("month");
  const yearParam = queryParams.get("year");

  const isRangeParamEmpty = !rangeParam;

  const [pageState, setPageState] = useState(PAGE_STATES.IDLE);
  const [drivers, setDrivers] = useState([]);
  const [selectedDriversFilter, setSelectedDriversFilter] = useState(
    driversParam?.split(",") || []
  );
  const [selectedRangeTypeFilter, setSelectedRangeTypeFilter] = useState(
    rangeParam || DEFAULT_RANGE_TYPE
  );
  const [selectedRangeDatesFilter, setSelectedRangeDatesFilter] = useState(
    DateRangeData[rangeParam || DEFAULT_RANGE_TYPE].getDates()
  );
  const [selectedMonthFilter, setSelectedMonthFilter] = useState(
    monthParam && yearParam
      ? new Date(Number(yearParam), Number(monthParam), 5)
      : null
  );

  const [anyFilterSelected, setAnyFilterSelected] = useState(false);

  const consolidatedReportsModal = useElement(ELEMENT_ID, {
    props: {
      onClose: () => {
        consolidatedReportsModal.deactivate();
      },
    },
  });
  const { unselectAllReports } = useTeamReports();

  const { checkAndHandleDunning } = useTeamsCTA();

  const loadDrivers = async function () {
    setPageState(PAGE_STATES.DRIVERS_LOADING);
    try {
      const members = await getTeamMembers(team?.id);
      setDrivers(
        members
          .filter((m) => {
            return MEMBER_STATUS.ACTIVE == m.status;
          })
          .map((m) => m.user)
      );
      setPageState(PAGE_STATES.DRIVERS_LOADED);
    } catch (err) {
      setPageState(PAGE_STATES.DRIVERS_ERROR);
      console.log("failed to load drivers");
    }
  };

  const getTrackingDriversFilter = () => {
    if (selectedDriversFilter.length === 0) {
      return null;
    }

    return selectedDriversFilter.length === 1
      ? teamsReportsDriversFilters.INDIVIDUAL
      : teamsReportsDriversFilters.MULTIPLE;
  };

  const getTrackingMonthFilter = () => {
    if (!selectedMonthFilter) {
      return null;
    }

    return format(selectedMonthFilter, "MMMM yyyy");
  };

  const getTrackingSubmissionFilter = () => {
    if (!selectedRangeTypeFilter || isRangeParamEmpty) {
      return null;
    }

    return teamsReportsSubmissionFilters[selectedRangeTypeFilter];
  };

  const onAnyFilterChanged = () => {
    unselectAllReports();
    if (!anyFilterSelected) setAnyFilterSelected(true);
  };

  const handleReportClicked = (report) => {
    history.push(`/teams/reports/${report.id}`, {
      fromReportList: true,
    });
  };

  const handleDateRangeFilterSelect = (rangeKey, range) => {
    queryParams.set("range", rangeKey);
    history.replace({ search: queryParams.toString() });

    trackTeamsReportsFiltered({
      orgId: team?.orgId,
      orgGroupId: team?.orgGroupId,
      subscriptionId: userData.subscriptionType,
      submissionFilter: teamsReportsSubmissionFilters[rangeKey],
      monthFilter: getTrackingMonthFilter(),
      drivesFilter: getTrackingDriversFilter(),
    });

    setSelectedRangeTypeFilter(rangeKey);
    setSelectedRangeDatesFilter(range);
    onAnyFilterChanged();
    console.log("New range:", rangeKey, range);
  };

  const handleDateRangeFilterClear = () => {
    queryParams.delete("range");
    history.replace({ search: queryParams.toString() });

    trackTeamsReportsFiltered({
      orgId: team?.orgId,
      orgGroupId: team?.orgGroupId,
      subscriptionId: userData.subscriptionType,
      monthFilter: getTrackingMonthFilter(),
      drivesFilter: getTrackingDriversFilter(),
    });

    setSelectedRangeTypeFilter(DEFAULT_RANGE_TYPE);
    setSelectedRangeDatesFilter(DateRangeData[DEFAULT_RANGE_TYPE].getDates());
    onAnyFilterChanged();
  };

  const handleMonthFilterSelect = (monthIdx, year) => {
    console.log(monthIdx);
    const d = setMonth(setYear(new Date(), year), monthIdx);

    queryParams.set("month", monthIdx);
    queryParams.set("year", year);
    history.replace({ search: queryParams.toString() });

    trackTeamsReportsFiltered({
      orgId: team?.orgId,
      orgGroupId: team?.orgGroupId,
      subscriptionId: userData.subscriptionType,
      monthFilter: format(d, "MMMM yyyy"),
      submissionFilter: getTrackingSubmissionFilter(),
      drivesFilter: getTrackingDriversFilter(),
    });

    setSelectedMonthFilter(d);
    onAnyFilterChanged();
  };

  const handleMonthFilterClear = () => {
    queryParams.delete("month");
    queryParams.delete("year");
    history.replace({ search: queryParams.toString() });

    trackTeamsReportsFiltered({
      orgId: team?.orgId,
      orgGroupId: team?.orgGroupId,
      subscriptionId: userData.subscriptionType,
      drivesFilter: getTrackingDriversFilter(),
      submissionFilter: getTrackingSubmissionFilter(),
    });

    setSelectedMonthFilter(null);
    onAnyFilterChanged();
  };

  const handleDriversFilterSelect = (drivers) => {
    const driversIds = drivers.map((d) => d.id);
    let drivesFilter = null;

    if (driversIds.length > 0) {
      queryParams.set("drivers", driversIds.join(","));
      drivesFilter =
        driversIds.length === 1
          ? teamsReportsDriversFilters.INDIVIDUAL
          : teamsReportsDriversFilters.MULTIPLE;
    } else {
      queryParams.delete("drivers");
    }

    history.replace({ search: queryParams.toString() });

    trackTeamsReportsFiltered({
      orgId: team?.orgId,
      orgGroupId: team?.orgGroupId,
      subscriptionId: userData.subscriptionType,
      drivesFilter,
      monthFilter: getTrackingMonthFilter(),
      submissionFilter: getTrackingSubmissionFilter(),
    });

    setSelectedDriversFilter(driversIds);
    onAnyFilterChanged();
  };

  const handleOpenConsolidatedReportsModal = () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    trackTeamsConsolidatedReportStarted({
      orgId: team?.orgId,
      orgGroupId: team?.orgGroupId,
      subscriptionId: userData.subscriptionType,
    });
    consolidatedReportsModal.activate();
  };

  const handleOpenFilters = () => {
    const dunningStatus = checkAndHandleDunning();

    return dunningStatus === DUNNING_STATUS.EXPIRED;
  };

  useEffect(() => {
    braze.logCustomEvent("teams_dash_reports_view");
    loadDrivers();
  }, []);

  const reportFilters = useMemo(
    () => ({
      dateRangeType: selectedRangeTypeFilter,
      dateRange: selectedRangeDatesFilter,
      drivers: selectedDriversFilter,
      monthOfDrives: selectedMonthFilter,
    }),
    [selectedRangeDatesFilter, selectedDriversFilter, selectedMonthFilter]
  );

  if (!team?.id) return null;

  return (
    <>
      <PageLayout className="page-reports">
        <PageLayout.Main>
          {pageState === PAGE_STATES.DRIVERS_LOADING && (
            <Loader
              className="p-8"
              message="Processing... This may take a few minutes depending on the number of reports."
              timeout={10000}
            />
          )}

          {pageState === PAGE_STATES.DRIVERS_LOADED && (
            <div className="h-full flex flex-col">
              <div className="p-5 page-header">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <MobMenu />
                    <h3>Reports</h3>
                  </div>
                  <div data-chmln="create-consolidated-report-button">
                    <Button
                      onClick={handleOpenConsolidatedReportsModal}
                      icon="copy-alt"
                    >
                      Create a report
                    </Button>
                  </div>
                </div>
                <div className="flex flex-wrap xl:flex-nowrap items-center mt-4 gap-3">
                  <div className="flex-shrink-0">
                    <DateRangePicker
                      onOpen={handleOpenFilters}
                      selectedRangeType={selectedRangeTypeFilter}
                      selectedRangeDates={selectedRangeDatesFilter}
                      onSelect={handleDateRangeFilterSelect}
                      onClear={handleDateRangeFilterClear}
                      excludeRangeTypes={[
                        DATE_RANGES.CUSTOM,
                        DATE_RANGES.YEAR_MONTH,
                      ]}
                      tooltip={{
                        text: "Filter by submission range",
                        alignX: "center",
                        offset: { y: 50 },
                      }}
                      startAsPristine={isRangeParamEmpty}
                    />
                  </div>
                  <div className="flex-shrink-0">
                    <FilterDrivers
                      onOpen={handleOpenFilters}
                      selected={selectedDriversFilter}
                      drivers={drivers}
                      onSelect={handleDriversFilterSelect}
                    />
                  </div>
                  <div className="flex-shrink-0">
                    <FilterMonths
                      onOpen={handleOpenFilters}
                      onSelect={handleMonthFilterSelect}
                      onClear={handleMonthFilterClear}
                      selected={selectedMonthFilter}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-grow">
                <ReportsList
                  onReportClicked={handleReportClicked}
                  filters={reportFilters}
                  withDefaultFilters={!anyFilterSelected}
                  teamDrivers={drivers}
                />
              </div>
            </div>
          )}
        </PageLayout.Main>
        <PageLayout.Sidebar>
          <ReportSidebar />
        </PageLayout.Sidebar>
      </PageLayout>
    </>
  );
}
