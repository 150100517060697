import Invite from "src/models/invite";
import { getStripeSubscriptionCode } from "src/models/team-subscription";

import { report } from "./error-reporting";

const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const PENDING_INVITES_KEY = "pending_invites";
const PENDING_TEAMS_SUBSCRIPTION_KEY = "pending_teams_subscription";
const WELCOME_TO_TEAMS_SLIDES_KEY = "welcome_to_teams_slides";
const SHOULD_TRACK_SIGNUP_COMPLETE = "should_track_signup_complete";
const SHOULD_SHOW_WELCOME_PAGE = "should_show_welcome_page";
const REMIND_BANNER_LAST_CALL_DATE = "remind_banner_last_call_date";
const BANNER_SIDENAV_CLOSED = "banner_sidenav_closed";
const PENDING_USER_SUBSCRIPTION_KEY = "pending_user_subscription";
const HAS_SEEN_WELCOME_TO_NEW_DASHBOARD_MODAL_KEY =
  "has_seen_welcome_to_new_dashboard";
const HAS_SEEN_FEEDBACK_SURVEY_FLASH = "has_seen_feedback_survey_flash";
const CHECKOUT_IN_PROGRESS = "checkout_in_progress";
const HAS_SEEN_BEST_EXP_MODAL = "has_seen_best_exp_modal";
const INVITE_USERS_AFTER_FREE_TRIAL_PURCHASE =
  "INVITE_USERS_AFTER_FREE_TRIAL_PURCHASE";
const DUNNING_PAYMENT_FAILED_MODAL_LAST_SEEN =
  "DUNNING_PAYMENT_FAILED_MODAL_LAST_SEEN";
const DUNNING_EXPIRED_SUBSCRIPTION_MODAL_LAST_SEEN =
  "DUNNING_EXPIRED_SUBSCRIPTION_MODAL_LAST_SEEN";

export function clean() {
  removeAccessToken(false);
  removeRefreshToken();
  removePendingUserSubscription();
  removePendingTeamsSubscription();
  removeCheckoutInProgress();
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export function removeCookie(name) {
  if (getCookie(name)) {
    document.cookie =
      name +
      "=;Domain=.mileiq.com;Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

// ------ SHOW FEEDBACK SURVEY FLASH

export function setHasSeenFeedbackSurveyFlash() {
  localStorage.setItem(HAS_SEEN_FEEDBACK_SURVEY_FLASH, 1);
}

export function getHasSeenFeedbackSurveyFlash() {
  return localStorage.getItem(HAS_SEEN_FEEDBACK_SURVEY_FLASH);
}

// ------ Access Token

export function setAccessToken(token) {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function removeAccessToken(redirect = true) {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  if (redirect) {
    const goTo = window.location.pathname + window.location.search;
    const url = `/logout?goTo=${encodeURIComponent(goTo)}`;
    return (window.location.href = url);
  }
}

// ------ Refresh Token

export function setRefreshToken(token) {
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function removeRefreshToken() {
  try {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  } catch (e) {
    report(e);
  }
}

// ------ Pending Teams Subscription

export function setPendingTeamsSubscription(plan, type) {
  localStorage.setItem(
    PENDING_TEAMS_SUBSCRIPTION_KEY,
    `${plan}:${type}:${getStripeSubscriptionCode(plan, type)}`
  );
}

export function getPendingTeamsSubscription() {
  try {
    const [plan, type, stripeSubCode] = localStorage
      .getItem(PENDING_TEAMS_SUBSCRIPTION_KEY)
      .split(":");
    return {
      plan,
      type,
      stripeSubCode,
    };
  } catch (err) {
    return {
      plan: null,
      type: null,
      stripeSubCode: null,
    };
  }
}

export function removePendingTeamsSubscription() {
  localStorage.removeItem(PENDING_TEAMS_SUBSCRIPTION_KEY);
}

// ------ Pending User Subscription

export function setPendingUserSubscription(data = {}) {
  localStorage.setItem(PENDING_USER_SUBSCRIPTION_KEY, JSON.stringify(data));
}

export function getPendingUserSubscription() {
  try {
    const data = JSON.parse(
      localStorage.getItem(PENDING_USER_SUBSCRIPTION_KEY)
    );
    return data;
  } catch (err) {
    console.log("Failed to parse pending sub data");
  }
  return {};
}

export function removePendingUserSubscription() {
  localStorage.removeItem(PENDING_USER_SUBSCRIPTION_KEY);
}

// ------ Pending Invites

export function setPendingInvites(teamId, invites) {
  const data = {
    [teamId]: invites.map((inv) => ({
      email: inv.email,
      userType: inv.userType,
    })),
  };
  localStorage.setItem(PENDING_INVITES_KEY, JSON.stringify(data));
}

export function getPendingInvites(teamId) {
  try {
    const data = JSON.parse(localStorage.getItem(PENDING_INVITES_KEY));
    if (data && data[teamId]) {
      return data[teamId].map((pi) => new Invite(pi.email, pi.userType));
    }
  } catch (err) {
    console.log("Failed to parse pending invites");
  }

  return null;
}

export function removePendingInvites() {
  localStorage.removeItem(PENDING_INVITES_KEY);
}

// ------ Welcome Slides

export function setShowWelcomeSlides(prevSubPlan, newSubPlan) {
  const data = {
    prevSubPlan,
    newSubPlan,
  };
  localStorage.setItem(WELCOME_TO_TEAMS_SLIDES_KEY, JSON.stringify(data));
}

export function getShowWelcomeSlides() {
  const data = JSON.parse(localStorage.getItem(WELCOME_TO_TEAMS_SLIDES_KEY));
  return data;
}

export function removeShowWelcomeSlides() {
  localStorage.removeItem(WELCOME_TO_TEAMS_SLIDES_KEY);
}

// ------ Track Signup Complete After Checkout

export function setShouldTrackSignupCompleteAfterCheckout(teamId) {
  localStorage.setItem(SHOULD_TRACK_SIGNUP_COMPLETE, teamId);
}

export function getShouldTrackSignupCompleteAfterCheckout() {
  return localStorage.getItem(SHOULD_TRACK_SIGNUP_COMPLETE);
}

export function removeShouldTrackSignupCompleteAfterCheckout() {
  localStorage.removeItem(SHOULD_TRACK_SIGNUP_COMPLETE);
}

// ------ Welcome Aboard Page

export function setShouldShowWelcomePage() {
  localStorage.setItem(SHOULD_SHOW_WELCOME_PAGE, 1);
}

export function getShouldShowWelcomePage() {
  return localStorage.getItem(SHOULD_SHOW_WELCOME_PAGE);
}

export function removeShouldShowWelcomePage() {
  localStorage.removeItem(SHOULD_SHOW_WELCOME_PAGE);
}

// ------ Invite users remind banner

export function setRemindBannerLastCallDate() {
  localStorage.setItem(REMIND_BANNER_LAST_CALL_DATE, new Date());
}

export function getRemindBannerLastCallDate() {
  return localStorage.getItem(REMIND_BANNER_LAST_CALL_DATE);
}

// ------ Banner Sidenav

export function setBannerSidenavClosed() {
  localStorage.setItem(BANNER_SIDENAV_CLOSED, new Date());
}

export function getBannerSidenavClosed() {
  return localStorage.getItem(BANNER_SIDENAV_CLOSED);
}

export function removeBannerSidenavClosed() {
  localStorage.removeItem(BANNER_SIDENAV_CLOSED);
}

// ------- Checkout in progress

export function setCheckoutInProgress() {
  sessionStorage.setItem(CHECKOUT_IN_PROGRESS, 1);
}

export function getCheckoutInProgress() {
  return sessionStorage.getItem(CHECKOUT_IN_PROGRESS);
}

export function removeCheckoutInProgress() {
  sessionStorage.removeItem(CHECKOUT_IN_PROGRESS);
}

// ------ Best Experience Modal
export function setSeenBestExpModal() {
  localStorage.setItem(HAS_SEEN_BEST_EXP_MODAL, new Date());
}
export function getSeenBestExpModal() {
  return localStorage.getItem(HAS_SEEN_BEST_EXP_MODAL);
}

// ------ Invite Users Modal

export function setInviteUsersAfterFreeTrialPurchase(teamId = "") {
  localStorage.setItem(
    `${teamId}_${INVITE_USERS_AFTER_FREE_TRIAL_PURCHASE}`,
    true
  );
}

export function getInviteUsersAfterFreeTrialPurchase(teamId = "") {
  return localStorage.getItem(
    `${teamId}_${INVITE_USERS_AFTER_FREE_TRIAL_PURCHASE}`
  );
}

export function removeInviteUsersAfterFreeTrialPurchase(teamId = "") {
  localStorage.removeItem(
    `${teamId}_${INVITE_USERS_AFTER_FREE_TRIAL_PURCHASE}`
  );
}
// ------ Dunning

export function setDunningPaymentFailedModalLastSeen(
  teamId = "",
  date = Date.now()
) {
  localStorage.setItem(
    `${teamId}_${DUNNING_PAYMENT_FAILED_MODAL_LAST_SEEN}`,
    date
  );
}

export function getDunningPaymentFailedModalLastSeen(teamId = "") {
  return localStorage.getItem(
    `${teamId}_${DUNNING_PAYMENT_FAILED_MODAL_LAST_SEEN}`
  );
}

export function setDunningExpiredSubscriptionModalLastSeen(
  teamId = "",
  date = Date.now()
) {
  localStorage.setItem(
    `${teamId}_${DUNNING_EXPIRED_SUBSCRIPTION_MODAL_LAST_SEEN}`,
    date
  );
}

export function getDunningExpiredSubscriptionModalLastSeen(teamId = "") {
  return localStorage.getItem(
    `${teamId}_${DUNNING_EXPIRED_SUBSCRIPTION_MODAL_LAST_SEEN}`
  );
}

export function setDriverDunningPaymentFailedModalLastSeen(
  teamId = "",
  userId = ""
) {
  localStorage.setItem(
    `${teamId}_${userId}_${DUNNING_PAYMENT_FAILED_MODAL_LAST_SEEN}`,
    Date.now()
  );
}

export function getDriverDunningPaymentFailedModalLastSeen(
  teamId = "",
  userId = ""
) {
  return localStorage.getItem(
    `${teamId}_${userId}_${DUNNING_PAYMENT_FAILED_MODAL_LAST_SEEN}`
  );
}

export function setDriverDunningExpiredSubscriptionModalLastSeen(
  teamId = "",
  userId = ""
) {
  localStorage.setItem(
    `${teamId}_${userId}_${DUNNING_EXPIRED_SUBSCRIPTION_MODAL_LAST_SEEN}`,
    Date.now()
  );
}

export function getDriverDunningExpiredSubscriptionModalLastSeen(
  teamId = "",
  userId = ""
) {
  return localStorage.getItem(
    `${teamId}_${userId}_${DUNNING_EXPIRED_SUBSCRIPTION_MODAL_LAST_SEEN}`
  );
}
