import { useMutation, useQuery } from "@apollo/client";
import { format, getMonth, getYear } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";
import useElement from "src/lib/layers/useElement";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Input from "src/components/elements/Input";
import Modal from "src/components/elements/Modal";
import Switcher from "src/components/elements/Switcher";
import Text from "src/components/elements/Text";
import Tooltip from "src/components/elements/Tooltip";

import { ELEMENT_ID as ADD_CONTACT_MODAL } from "src/components/modals/AddContact";

import {
  trackContactAddCompleted,
  trackContactAddStarted,
  trackExperimentViewed,
  trackreportCreationStepCompleted,
} from "src/services/tracking";
import {
  COUNTRIES,
  getTimeZoneDiffInHours,
  isValidEmail,
} from "src/services/utils";

import { ADD_CUSTOM_REPORT } from "src/graphql/mutations";
import { GET_RATES } from "src/graphql/queries";

import CurrencyInput from "../blocks/inputs/CurrencyInput";
import ConcurReport from "../blocks/reports/ConcurReport";
import { useContacts } from "../context/ContactsContext";
import UserContext, { UserDataContext } from "../context/UserContext";

export default ReportActions;

export const ELEMENT_ID = "REPORT_ACTIONSV2";
registerElement(ELEMENT_ID, ReportActions);

function ReportActions({ onClose, reportData, onReportSent }) {
  const [step, setStep] = useState(0);
  const [data, setData] = useState(reportData);

  const handleNextStep = (data) => {
    setStep(1);
    setData(data);
  };

  useEffect(() => {
    trackExperimentViewed({
      experimentId: 15,
      experimentName: "Contacts",
      variationId: 2,
      variationName: "V1 (Contacts)",
    });
  }, []);

  return (
    <Modal closable onClose={onClose} className="w-[420px] laptop:w-[360px]">
      <>
        {step == 0 && (
          <FirstStep reportData={reportData} onNext={handleNextStep} />
        )}
        {step == 1 && (
          <SecondStep
            currentData={data?.variables?.data}
            onReportSent={onReportSent}
          />
        )}
      </>
    </Modal>
  );
}

const FirstStep = ({ reportData, onNext }) => {
  const { userData } = useContext(UserDataContext);
  const ratesQuery = useQuery(GET_RATES, {
    notifyOnNetworkStatusChange: true,
  });

  const [currentBusinessRate, setCurrentBusinessRate] = useState(null);
  const timeZoneDiffInHours = getTimeZoneDiffInHours();
  const [data, setData] = useState({ shouldReport: true });
  const [reportError, setReportError] = useState(null);
  const iconRef = useRef(null);

  useEffect(() => {
    if (reportError) {
      setReportError(null);
    }
  }, [data]);

  useEffect(() => {
    let rateObj = null;
    if (userData.country === COUNTRIES.US) {
      rateObj = ratesQuery?.data?.rates.find(
        (rate) => rate.year === getYear(new Date())
      );
    }

    if (rateObj) setCurrentBusinessRate(JSON.parse(rateObj.dataJSON));
  }, [ratesQuery.data?.rates]);

  const onSubmit = (additionalData = {}) => {
    const { reimbursementRates, ...otherData } = data;
    let customData = {};
    if (reportData.driveIds) {
      customData = {
        driveIds: reportData.driveIds,
        firstTimeZoneDiffInHours: timeZoneDiffInHours,
        lastTimeZoneDiffInHours: timeZoneDiffInHours,
      };
    } else {
      customData = {
        startTimestamp: reportData.dateFrom.toISOString(),
        endTimestamp: reportData.dateTo.toISOString(),
        vehicleIds: reportData.vehicleIds,
        categoryIds: reportData.categoryId,
        firstTimeZoneDiffInHours: getTimeZoneDiffInHours(reportData.dateFrom),
        lastTimeZoneDiffInHours: getTimeZoneDiffInHours(reportData.dateTo),
      };
    }

    trackreportCreationStepCompleted({
      subscriptionId: userData?.subscriptionType,
    });

    onNext({
      variables: {
        data: {
          ...otherData,
          ...customData,
          ...additionalData,
          reimbursementRates: {
            business: +reimbursementRates?.business || null,
          },
          useMetric: userData.distanceUnit === "km" ? "t" : "f",
        },
      },
    });
  };

  return (
    <div
      data-testid="send-to-custom"
      className="flex flex-col items-center justify-center"
    >
      <div className="w-[393px] h-[30px] mb-[20px] justify-center items-center gap-2.5 inline-flex">
        <div className="justify-start items-center gap-3 flex">
          <div className="w-[30px] h-[30px] p-2.5 bg-blue rounded-[100px] flex-col justify-center items-center gap-2.5 inline-flex">
            <div className="text-center text-white text-[15px] font-medium leading-[21px]">
              1
            </div>
          </div>
        </div>
        <span className="w-[20px] h-[1px] bg-black/20"></span>
        <div className="justify-start items-center gap-3 flex">
          <div className="w-[30px] h-[30px] p-2.5 bg-beige rounded-[100px] flex-col justify-center items-center gap-2.5 inline-flex">
            <div className="text-center text-black text-opacity-50 text-[15px] font-medium leading-[21px]">
              2
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-[20px] laptop:gap-[15px]">
        <h4 className="text-center">Add details</h4>
        <>
          <Input
            value={data.reportName}
            onChange={(e) => setData({ ...data, reportName: e.target.value })}
            label={
              <span>
                Report name<strong className="!text-[red]">*</strong>
              </span>
            }
            placeholder="Report name"
          />
          <div className="grid grid-cols-2 gap-4">
            <Input
              label="Submitted by"
              value={data.submitter}
              onChange={(e) => setData({ ...data, submitter: e.target.value })}
              placeholder="Name"
            />
            <Input
              value={data.client}
              onChange={(e) => setData({ ...data, client: e.target.value })}
              label="Client or customer"
              placeholder="Client or customer"
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Input
              value={data.project}
              onChange={(e) => setData({ ...data, project: e.target.value })}
              label="Project"
              placeholder="Project"
            />
            {userData.country === COUNTRIES.US && (
              <CurrencyInput
                fractionalDigits={3}
                value={data.reimbursementRates?.business}
                onChange={(e) =>
                  setData({
                    ...data,
                    reimbursementRates: { business: e.target.value },
                  })
                }
                noCurrency
                label="Business rate($)"
                placeholder={`${currentBusinessRate?.values?.business?.default}/mi`}
              />
            )}
          </div>
          <div className="py-2 border-y border-solid border-border-1 flex justify-between items-center">
            <div className="flex">
              <Text semibold>Mark drives as reported</Text>
              <Icon className="ml-2" name="question-circle" iconRef={iconRef} />
            </div>
            <Tooltip
              triggerRef={iconRef}
              offset={{
                y: 25,
                x: -130,
              }}
            >
              <Text sm nowrap>
                Select this option to see which drives have been reported.
              </Text>
            </Tooltip>
            <Switcher
              lg
              isOn={data.shouldReport}
              onChange={() =>
                setData({ ...data, shouldReport: !data.shouldReport })
              }
            />
          </div>
          {reportError && (
            <Text
              md
              color="red"
              regular
              paragraph
              className="w-full text-left mt-2"
            >
              {reportError}
            </Text>
          )}
          <Button
            lg
            onClick={() => onSubmit()}
            disabled={
              !data.reportName?.trim() ||
              (data.cc?.trim() && !isValidEmail(data.cc.trim()))
            }
          >
            Next
          </Button>
        </>
      </div>
    </div>
  );
};

const SecondStep = ({
  currentData,
  onReportSent,
  onIdentitySet,
  initialService = "email",
}) => {
  const { user } = useContext(UserContext);
  const { userData } = useContext(UserDataContext);
  const { contacts } = useContacts();

  const [selectedService, setSelectedService] = useState(initialService);
  const [addCustomReport, { loading }] = useMutation(ADD_CUSTOM_REPORT, {
    notifyOnNetworkStatusChange: true,
  });
  const [selected, select] = useState(null);
  const [reportError, setReportError] = useState(null);
  const iconRef = useRef(null);

  const addContactModal = useElement(ADD_CONTACT_MODAL, {
    props: {
      onClose: () => {
        addContactModal.deactivate();
      },
      onSubmit: (contact) => {
        addContactModal.deactivate();
        handleContactSelect(contact);
        trackContactAddCompleted({
          subscriptionId: userData?.subscriptionType,
          page: "Share Report",
          contactRelationship: contact.relationship,
        });
      },
    },
  });

  const canSubmit = selected && !loading;

  useEffect(() => {
    if (reportError) {
      setReportError(null);
    }
  }, []);

  const handleSubmit = (additionalData = {}) => {
    if (!selected) return;
    let ccContactIds = [];
    if (selected?.email !== user?.email) {
      ccContactIds.push(selected.id);
    }
    setReportError(null);

    const email = selected?.email;

    const payload = {
      ...currentData,
      ...additionalData,
      cc_contact_ids: ccContactIds,
      // cc: user?.email,
    };

    const formatDate = (startDate, endDate) => {
      const shortMonthsTitleDateFormats = {
        month: ["MMMM", "MMM, yyyy"],
        custom: ["MMMM", "MMM, yyyy"],
        range: ["MMMM", "MMM, yyyy"],
      };

      if (getYear(startDate) === getYear(new Date())) {
        if (getMonth(startDate) === getMonth(endDate)) {
          return `${format(startDate, shortMonthsTitleDateFormats.month[0])}`;
        }
        return `${format(
          startDate,
          shortMonthsTitleDateFormats.month[0]
        )} - ${format(endDate, shortMonthsTitleDateFormats.month[0])}`;
      } else {
        if (getMonth(startDate) === getMonth(endDate)) {
          return `${format(startDate, shortMonthsTitleDateFormats.month[1])}`;
        }
        return `${format(
          startDate,
          shortMonthsTitleDateFormats.month[1]
        )} - ${format(endDate, shortMonthsTitleDateFormats.month[1])}`;
      }
    };

    const successTitle = (() => {
      if (payload.driveIds) {
        return `Report has been sent to ${email}`;
      }
      const timeRange = formatDate(
        new Date(payload.startTimestamp),
        new Date(payload.endTimestamp)
      );
      return `${timeRange} report has been submitted to ${email}`;
    })();

    addCustomReport({
      variables: {
        data: payload,
      },
    }).then((res) => {
      const success = res?.data?.addCustomReport?.ok;
      if (!success) {
        setReportError(
          "Failed to send custom report, please try again later or contact support."
        );
      }
      onReportSent(success, successTitle, {
        recipientType: ccContactIds?.length ? "contact" : "myself",
        contactRelationship: selected.relationship,
        markAsReported: payload?.shouldReport,
      });
    });
  };

  const handleContactSelect = (contact) => {
    select(selected?.email === contact?.email ? null : contact);
  };

  const handleAddContact = () => {
    addContactModal.activate();
    trackContactAddStarted({
      page: "Share Report",
      subscriptionId: userData?.subscriptionType,
    });
  };

  const isEmailAdded = (email) => {
    return selected?.email === email;
  };

  return (
    <div
      data-testid="send-to-custom"
      className="flex flex-col items-center justify-center"
    >
      <div className="w-[393px] h-[30px] mb-[20px] justify-center items-center gap-2.5 inline-flex">
        <div className="justify-start items-center gap-3 flex">
          <div className="w-[30px] h-[30px] p-2.5 bg-beige rounded-[100px] flex-col justify-center items-center gap-2.5 inline-flex">
            <div className="text-center text-black text-opacity-50 text-[15px] font-medium leading-[21px]">
              1
            </div>
          </div>
        </div>
        <span className="w-[20px] h-[1px] bg-black/20"></span>
        <div className="justify-start items-center gap-3 flex">
          <div className="w-[30px] h-[30px] p-2.5 bg-blue rounded-[100px] flex-col justify-center items-center gap-2.5 inline-flex">
            <div className="text-center text-white text-[15px] font-medium leading-[21px]">
              2
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-[20px] laptop:gap-[15px]">
        <h4 className="text-center">Share report</h4>
        <div className="flex w-full gap-3">
          <Button
            lg
            ghost
            onClick={() => setSelectedService("email")}
            className={`flex-1 font-medium ${
              selectedService == "email"
                ? "bg-blue/5 border-2 border-blue"
                : "bg-white border border-border-1"
            }`}
            icon="mail"
          >
            Email
          </Button>
          <Button
            lg
            ghost
            onClick={() => setSelectedService("concur")}
            className={`flex-1 font-medium ${
              selectedService == "concur"
                ? "bg-blue/5 border-2 border-blue"
                : "bg-white border border-border-1"
            }`}
            icon="concur"
          >
            Concur
          </Button>
        </div>
        {selectedService === "email" && (
          <>
            <div>
              <Text semibold>Send to</Text>
              <Text paragraph className="text-black/70">
                Choose a contact to share this report with
              </Text>
            </div>
            <div className="flex flex-wrap gap-2">
              {[
                { email: user.email, firstName: "Myself only" },
                ...(contacts || []),
              ]?.map((contact) => (
                <span
                  title={contact?.email}
                  key={contact?.email}
                  className={`truncate max-w-full hover:cursor-pointer leading-[34px] px-5 font-medium rounded-[100px] ${
                    isEmailAdded(contact?.email)
                      ? "bg-black text-white"
                      : "bg-beige text-black"
                  }`}
                  onClick={() => handleContactSelect(contact)}
                >
                  {contact?.firstName}
                </span>
              ))}
            </div>
            <div>
              <span
                onClick={handleAddContact}
                className="text-blue hover:cursor-pointer"
              >
                + Add a contact
              </span>
            </div>
            <div className="py-2 border-y border-t-solid border-t-border-1 border-b-0 flex justify-between items-center">
              <div className="flex gap-[15px]">
                <Icon
                  className="rotate-[180deg]"
                  name="alert"
                  iconRef={iconRef}
                />
                <Text paragraph md className="text-black/70 leading-[18px]">
                  When sharing a report with a contact, we'll also send you a
                  copy
                </Text>
              </div>
            </div>
            {reportError && (
              <Text
                md
                color="red"
                regular
                paragraph
                className="w-full text-left mt-2"
              >
                {reportError}
              </Text>
            )}
            <Button
              lg
              onClick={() => handleSubmit()}
              loading={loading}
              disabled={!canSubmit || loading}
            >
              Send report
            </Button>
          </>
        )}
        {selectedService === "concur" && (
          <ConcurReport
            onSubmit={handleSubmit}
            submitLoading={loading}
            reportError={reportError}
            clearError={() => setReportError(null)}
            onIdentitySet={onIdentitySet}
          />
        )}
      </div>
    </div>
  );
};
